var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('hr',{staticClass:"yellow-rule"}),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v(_vm._s(_vm.$t("company_listing"))+_vm._s(_vm.additionalTitle))]),_c('Underline')],1)],1),(_vm.roles)?_c('v-row',{staticClass:"flex-nowrap",attrs:{"align":"center"}},[_c('v-col',{attrs:{"sm":"6","cols":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","placeholder":_vm.$t('search_companies'),"single-line":"","hide-details":"","rounded":"","outlined":"","dense":""},model:{value:(_vm.search.name),callback:function ($$v) {_vm.$set(_vm.search, "name", $$v)},expression:"search.name"}})],1),_c('v-col',{attrs:{"sm":"3","cols":"6"}},[_c('v-select',{staticClass:"mb-0",attrs:{"clearable":"","placeholder":_vm.$t('all_roles'),"hide-details":"","items":_vm.roles,"item-text":"name","item-value":"slug","outlined":"","dense":""},model:{value:(_vm.search.role),callback:function ($$v) {_vm.$set(_vm.search, "role", $$v)},expression:"search.role"}})],1),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"secondary","to":_vm.$i18nRoute({ name: 'submitCompensationSelection' }),"dark":"","rounded":""}},[_c('v-icon',{attrs:{"left":"","dark":"","large":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("submit_compensation"))+" ")],1)],1):_vm._e()],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"mb-4",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.companies.items,"options":_vm.dataTableOptions,"custom-filter":_vm.companySearch,"footer-props":{ 'items-per-page-options': [15, 30, 50, -1] },"sort-by":"salary_entries.stats.count","sort-desc":"","search":_vm.search.name},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('h4',[_c('router-link',{attrs:{"to":_vm.$i18nRoute({
                    name: 'showCompany',
                    params: {
                      company_slug: item.slug,
                      role_slug: _vm.search.role || 'software-engineer'
                    }
                  })}},[_vm._v(_vm._s(item.name))])],1)]}},{key:"item.annual_bonus",fn:function(ref){
                  var item = ref.item;
return [_c('horizontal-scatter-chart',{attrs:{"height":_vm.$vuetify.breakpoint.mobile ? 45 : 20,"width":250,"label":item.name,"dataset":item.salary_entries.items,"min":300000,"max":50000000,"locale":_vm.locale.code}})]}},{key:"item.salary_entries.stats.median",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayCompensationItem(item.salary_entries.stats.median))+" ")]}}])}),_c('v-row',{staticClass:"mb-4",attrs:{"justify":"center"}},[_c('h2',[_vm._v(_vm._s(_vm.$t("call_to_add")))])]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"color":"secondary","to":{
              path: 'submit-compensation-selection',
              params: { locale: _vm.locale }
            },"large":"","dark":"","rounded":""}},[_c('v-icon',{attrs:{"left":"","dark":"","large":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("submit_compensation"))+" ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }