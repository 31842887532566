<template>
  <div>
    <hr class="yellow-rule" />
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1>{{ $t("company_listing") }}{{ additionalTitle }}</h1>
          <Underline />
        </v-col>
      </v-row>
      <!-- Search and filter -->
      <v-row v-if="roles" align="center" class="flex-nowrap">
        <v-col sm="6" cols="6">
          <v-text-field
            v-model="search.name"
            append-icon="mdi-magnify"
            :placeholder="$t('search_companies')"
            single-line
            hide-details
            rounded
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col sm="3" cols="6">
          <v-select
            clearable
            :placeholder="$t('all_roles')"
            hide-details
            v-model="search.role"
            :items="roles"
            item-text="name"
            item-value="slug"
            outlined
            class="mb-0"
            dense
          />
        </v-col>
        <v-col class="text-right" v-if="$vuetify.breakpoint.smAndUp">
          <v-btn
            color="secondary"
            :to="$i18nRoute({ name: 'submitCompensationSelection' })"
            dark
            rounded
          >
            <v-icon left dark large>mdi-plus</v-icon>
            {{ $t("submit_compensation") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            class="mb-4"
            :loading="loading"
            :headers="headers"
            :items="companies.items"
            :options="dataTableOptions"
            :custom-filter="companySearch"
            :footer-props="{ 'items-per-page-options': [15, 30, 50, -1] }"
            sort-by="salary_entries.stats.count"
            sort-desc
            :search="search.name"
          >
            <template v-slot:item.name="{ item }">
              <h4>
                <router-link
                  :to="
                    $i18nRoute({
                      name: 'showCompany',
                      params: {
                        company_slug: item.slug,
                        role_slug: search.role || 'software-engineer'
                      }
                    })
                  "
                  >{{ item.name }}</router-link
                >
              </h4>
            </template>
            <template v-slot:item.annual_bonus="{ item }">
              <horizontal-scatter-chart
                :height="$vuetify.breakpoint.mobile ? 45 : 20"
                :width="250"
                :label="item.name"
                :dataset="item.salary_entries.items"
                :min="300000"
                :max="50000000"
                :locale="locale.code"
              ></horizontal-scatter-chart>
            </template>
            <template v-slot:item.salary_entries.stats.median="{ item }">
              {{ displayCompensationItem(item.salary_entries.stats.median) }}
            </template>
          </v-data-table>
          <v-row class="mb-4" justify="center">
            <h2>{{ $t("call_to_add") }}</h2>
          </v-row>
          <v-row justify="center">
            <v-btn
              color="secondary"
              :to="{
                path: 'submit-compensation-selection',
                params: { locale: locale }
              }"
              large
              dark
              rounded
            >
              <v-icon left dark large>mdi-plus</v-icon>
              {{ $t("submit_compensation") }}
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<i18n>
{
  "en": {
    "company_listing": "Companies",
    "company_name": "Company Name",
    "compensation_range": "Compensation Range",
    "compensation_entries": "# of Entries",
    "median_compensation": "Median",
    "submit_compensation": "Submit compensation",
    "call_to_add": "Help others like you, add your own compensation!",
    "search_companies": "Search Companies",
    "meta_description": "Explore and compare {role} salaries at the top companies in Japan.",
    "software_engineer": "Software Engineer",
    "product_manager": "Product Manager",
    "all_roles": "All Roles"
  },
  "ja": {
    "company_listing": "企業一覧",
    "company_name": "企業名",
    "compensation_entries": "件",
    "compensation_range": "年収範囲",
    "median_compensation": "中央値",
    "submit_compensation": "年収を追加",
    "call_to_add": "皆さんの情報で、企業の年収をよりオープンに可視化しましょう！",
    "search_companies": "社名で検索する",
    "meta_description": "日本の企業で働く{role}の年収を検索＆比較。",
    "software_engineer": "ソフトウェアエンジニア",
    "product_manager": "プロダクトマネージャー",
    "all_roles": "全て職種"
  }
}
</i18n>

<script>
import store from "store";
import api from "services/api";
import Underline from "components/Underline.vue";
import HorizontalScatterChart from "components/HorizontalScatter.chart.vue";
import { displayCompensationItemMixin } from "../mixins/displayCompensationItemMixin";

export default {
  mixins: [displayCompensationItemMixin],
  props: {
    role: String
  },
  components: {
    Underline,
    HorizontalScatterChart
  },
  data: () => ({
    search: {
      name: "",
      role: null
    },
    roles: [],
    currentRole: null,
    companies: [],
    dataTableOptions: {
      itemsPerPage: 30
    },
    loading: false
  }),
  metaInfo() {
    // TODO fix metatitle tag to be different if no filter
    const role = this.currentRole
      ? this.currentRole.name
      : this.$t("software_engineer");
    return {
      title: this.$t("company_listing"),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.$t("meta_description", { role: role })
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: this.$t("company_listing") + this.additionalTitle
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          content: this.$t("meta_description", { role: role })
        },
        {
          vmid: "og-title",
          property: "og:title",
          title: this.$t("company_listing") + this.additionalTitle
        },
        {
          vmid: "og-description",
          property: "og:description",
          content: this.$t("meta_description", { role: role })
        }
      ]
    };
  },
  created() {
    this.getJobRoles();
    this.getCompanies();
  },
  methods: {
    getJobRoles() {
      api.getJobRoles().then(resp => {
        this.roles = resp.data.items;
        const urlRoleParam = this.$route.query.role;
        this.currentRole = this.roles.find(role => role.slug === urlRoleParam);
        this.search.role = this.roles.find(
          role => role.slug === urlRoleParam
        ).slug;
      });
    },
    getCompanies() {
      this.loading = "grey";
      const urlRoleParam = this.$route.query.role;
      const params = {
        with_salary_entries: true,
        job_role: urlRoleParam
      };
      api.getCompanies(params).then(
        resp => {
          this.companies = resp.data;
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
    },
    companySearch(value, typedText, company) {
      // TODO: improve the search for all Japanese characters
      // https://github.com/drewpterry/project-alpha/issues/732

      const hasValue = val => (val != null ? val : "");

      const nameEn = hasValue(company.name_en)
        .toString()
        .toLowerCase();
      const nameJa = hasValue(company.name_ja).toString();
      let searchTerm = hasValue(typedText);

      // if search term are English letters
      if (/^[a-zA-Z]/.test(searchTerm)) {
        searchTerm = searchTerm.toLowerCase();
      } else {
        searchTerm = searchTerm.toString();
      }

      const matchNameEn = nameEn.indexOf(searchTerm) > -1;
      const matchNameJa = nameJa.indexOf(searchTerm) > -1;

      return matchNameEn || matchNameJa;
    }
  },
  watch: {
    "search.role"() {
      const newSlug = this.search.role
        ? this.roles.find(role => role.slug === this.search.role).slug
        : this.search.role;
      this.$router.replace({
        query: this.search.role ? { role: newSlug } : null
      });

      this.currentRole = this.roles.find(
        role => role.slug === this.search.role
      );
    },
    $route() {
      this.getCompanies();
    }
  },
  computed: {
    locale() {
      return store.getters.locale;
    },
    headers() {
      const allHeaders = [
        {
          text: this.$t("company_name"),
          value: "name",
          width: "200"
        },
        {
          text: this.$t("compensation_range"),
          value: "annual_bonus",
          sortable: false
        },
        {
          text: this.$t("median_compensation"),
          value: "salary_entries.stats.median",
          width: "110",
          align: "right"
        },
        {
          text: this.$t("compensation_entries"),
          value: "salary_entries.stats.count"
        }
      ];

      return allHeaders;
    },
    additionalTitle() {
      return this.currentRole ? ` - ${this.currentRole.name}` : "";
    }
  }
};
</script>
